/**
 * Css variables
 */
:root {
  --header-height: 80px;
}

/**
 * Layout 
 * Inner width sizes used in components and media queries. 
 */
* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

html {
  scroll-padding-top: var(--header-height);
  background-color: #005293;
}

body {
  padding-top: var(--header-height);
  background-color: #fff;
}

body,
.wrapper {
  overflow-x: hidden;
}

figure {
  position: relative;
  margin: 0;
}

img {
  border: 0;
  max-width: 100%;
  height: auto;
}

iframe,
video {
  width: 100%;
  height: auto;
}

label {
  display: block;
  font-family: "inconsolata", monospace;
  font-size: 1rem;
  line-height: 1;
}

input[type=text],
input[type=email] {
  border: 1px solid #005293;
  width: 100%;
  padding: 10px;
  font-family: "inconsolata", monospace;
  font-size: 1.2rem;
  line-height: 1;
}

@font-face {
  font-family: "inconsolata";
  src: url("../fonts/inconsolata-regular.woff2") format("woff2"), url("../fonts/inconsolata-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "inconsolata";
  src: url("../fonts/inconsolata-bold.woff2") format("woff2"), url("../fonts/inconsolata-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "opensans";
  src: url("../fonts/opensans-regular.woff2") format("woff2"), url("../fonts/opensans-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "opensans";
  src: url("../fonts/opensans-italic.woff2") format("woff2"), url("../fonts/opensans-italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "opensans";
  src: url("../fonts/opensans-bold.woff2") format("woff2"), url("../fonts/opensans-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
body {
  font-family: "opensans", helvetica, arial, sans-serif;
  font-size: 100%;
  line-height: 1.4;
  color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 15px;
  font-family: "opensans", helvetica, arial, sans-serif;
  font-weight: 400;
  letter-spacing: -1px;
}

h1 {
  margin: 0 0 4px;
  font-size: 4.5rem;
  line-height: 0.85;
  font-weight: 700;
  letter-spacing: -4px;
}

h2 {
  font-size: 2.875rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 2rem;
}

h5 {
  font-size: 1.75rem;
}

h6 {
  font-size: 1.5rem;
}

p {
  margin: 0 0 15px;
}
p:empty {
  display: none;
}
p:last-child {
  margin-bottom: 0;
}

a {
  color: #005293;
}

small,
figcaption {
  font-family: "inconsolata", monospace;
  font-size: 0.9rem;
  letter-spacing: -0.5px;
}

.title {
  line-height: 1;
  font-weight: 700;
}

.pretitle {
  display: inline-block;
  margin: 0 0 8px;
  border-radius: 2px;
  padding: 4px 6px 2px;
  font-family: "inconsolata", monospace;
  font-size: 1rem;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  color: #fecb00;
  background-color: #005293;
}
.pretitle--is-inverted {
  color: #005293;
  background-color: #fecb00;
}

.preamble {
  font-size: 1.25rem;
  letter-spacing: -0.5px;
}

.details {
  font-size: 1rem;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.symbol {
  max-width: none;
}
.symbol path {
  fill: rgba(0, 82, 147, 0.5);
}
.symbol--is-domino {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: 100vh;
  transform: rotate(15deg) translate(-50%, -50%);
}
.symbol--is-domino path {
  fill: rgba(0, 82, 147, 0.25);
}
.symbol--is-grade-up {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: auto;
  transform: rotate(-45deg);
}
.symbol--is-epsilon {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 140px;
  height: auto;
  transform: rotate(40deg);
}

*:focus {
  outline: 2px dotted #000;
}

.a11y-sr-only {
  position: absolute;
  border: 0;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  clip: rect(0, 0, 0, 0);
}
.a11y-skip-to {
  position: absolute;
  display: inline-block;
  left: -100%;
  z-index: -999;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.a11y-skip-to:focus, .a11y-skip-to:active {
  left: 50%;
  z-index: 9999;
  margin: 0 auto;
  border: 2px solid #000;
  width: auto;
  height: auto;
  padding: 15px;
  font-size: 1rem;
  line-height: 1.25;
  color: #000;
  text-align: center;
  overflow: auto;
  background-color: #fff;
  transform: translateX(-50%);
}

.inner {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}
.inner--is-xxl {
  max-width: 1680px;
}
.inner--is-xl {
  max-width: 1440px;
}
.inner--is-l {
  max-width: 1280px;
}
.inner--is-m {
  max-width: 1024px;
}
.inner--is-s {
  max-width: 768px;
}
.inner--is-xs {
  max-width: 480px;
}
.inner--is-xxs {
  max-width: 320px;
}

/**
 * Row
 */
.row {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 30px;
}
.row:last-child {
  margin-bottom: 0;
}
.row--is-reversed {
  flex-direction: row-reverse;
}
@media (max-width: 768px) {
  .row--is-reversed {
    flex-direction: row;
  }
}

/**
 * Column
 */
.col {
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 50%;
}
.col__inner {
  padding: 30px;
}
.col--is-frosted .col__inner {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
}
.col--has-symbols {
  height: 60vh;
}
.col--has-symbols figure {
  height: 100%;
}
@media (max-width: 768px) {
  .col {
    margin-bottom: 30px;
    flex: 0 0 100%;
  }
  .col--has-symbols {
    height: 100px;
  }
}

.img--is-bordered {
  border: 5px solid #fecb00;
  box-shadow: 10px 10px 0 0 #005293;
}

.button,
button,
button[type=submit],
input[type=submit],
.wp-block-button__link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin: 0;
  border: 1px solid #005293;
  border-radius: 100px;
  padding: 15px 30px;
  font-family: "inconsolata", monospace;
  font-size: 1rem;
  line-height: inherit;
  color: white;
  background-color: #005293;
  text-decoration: none;
  appearance: none;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.3s ease-out;
}
.button svg,
button svg,
button[type=submit] svg,
input[type=submit] svg,
.wp-block-button__link svg {
  max-width: none;
  width: auto;
  height: 16px;
}
.button:focus,
button:focus,
button[type=submit]:focus,
input[type=submit]:focus,
.wp-block-button__link:focus {
  transform: scale(1.05);
}
@media (hover: hover) {
  .button:hover,
  button:hover,
  button[type=submit]:hover,
  input[type=submit]:hover,
  .wp-block-button__link:hover {
    transform: scale(1.05);
  }
}
.button:active,
button:active,
button[type=submit]:active,
input[type=submit]:active,
.wp-block-button__link:active {
  transform: scale(1);
}
.button[disabled],
button[disabled],
button[type=submit][disabled],
input[type=submit][disabled],
.wp-block-button__link[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

.form {
  display: flex;
  align-items: center;
}
.form__row {
  margin-top: 30px;
}

.agenda-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.agenda-list li {
  margin-bottom: 15px;
}
.agenda-list__time {
  display: block;
  margin-right: 15px;
  font-family: "inconsolata", monospace;
}
.agenda-list__title {
  margin-bottom: 4px;
  font-size: 1.1rem;
  font-weight: 700;
}
.agenda-list__desc {
  font-family: "inconsolata", monospace;
  font-size: 0.9rem;
}

.attendee-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.attendee-list li span:first-child {
  font-weight: 700;
}
.attendee-list li span:last-child {
  font-style: italic;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  height: var(--header-height);
  padding-left: 30px;
  padding-right: 30px;
  color: #222;
  background-color: rgba(248, 248, 255, 0.95);
}
.header__inner {
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}
.header__logo {
  position: relative;
  height: 18px;
  line-height: 0;
}
.header__logo__link {
  position: relative;
  display: block;
  height: 100%;
}
.header__logo__img {
  max-width: none;
  width: auto;
  height: 100%;
}
.header__logo__img path {
  fill: #000;
}
.header__btn {
  display: none;
  border-radius: 0;
  border-color: transparent;
  width: var(--header-height);
  height: 100%;
  padding: 0;
  background-color: transparent;
}
.header__btn svg path {
  fill: #005293;
}
@media (hover: hover) {
  .header__btn:hover {
    transform: none;
    transition: none;
  }
}
@media (max-width: 768px) {
  .header__btn {
    display: inline-flex;
  }
  .header__btn[aria-expanded=true] + .header__nav {
    display: block;
    background-color: #005293;
  }
}
.header__nav {
  position: relative;
  height: 100%;
}
.header__nav ul {
  display: flex;
  margin: 0;
  height: 100%;
  padding: 0;
  align-items: center;
  list-style: none;
}
.header__nav li {
  display: flex;
  height: 100%;
}
.header__nav li.current-menu-item, .header__nav li.current_page_item {
  border-bottom-color: #005293;
}
.header__nav li.current-menu-item a, .header__nav li.current_page_item a {
  border-bottom-color: #005293;
  color: #005293;
}
.header__nav li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  border-bottom: 2px solid transparent;
  padding: 0 15px;
  font-family: "inconsolata", monospace;
  font-size: 0.875rem;
  font-weight: 700;
  color: #222;
  text-transform: uppercase;
}
.header__nav li a:focus {
  border-bottom-color: #005293;
  color: #005293;
}
@media (hover: hover) {
  .header__nav li a:hover {
    border-bottom-color: #005293;
    color: #005293;
  }
}
.header__nav li a:active {
  border-bottom-color: green;
  color: #005293;
}
@media (max-width: 768px) {
  .header__nav {
    display: none;
    position: fixed;
    top: var(--header-height);
    height: calc(100vh - var(--header-height));
    left: 0;
    right: 0;
    z-index: 999;
    overflow-y: scroll;
  }
  .header__nav ul {
    display: block;
    height: auto;
  }
  .header__nav li {
    display: block;
    height: auto;
  }
  .header__nav li.current-menu-item, .header__nav li.current_page_item {
    border-bottom-color: #000;
  }
  .header__nav li.current-menu-item a, .header__nav li.current_page_item a {
    border-bottom-color: #fecb00;
    color: #fecb00;
  }
  .header__nav li a {
    border-bottom: 1px dashed #fecb00;
    padding: 30px;
    font-size: 1.125rem;
    color: #fecb00;
    font-weight: 400;
    text-transform: none;
  }
  .header__nav li a:focus {
    border-bottom-color: #fecb00;
    color: white;
  }
}
@media (max-width: 768px) and (hover: hover) {
  .header__nav li a:hover {
    border-bottom-color: white;
    color: #fecb00;
  }
}
@media (max-width: 768px) {
  .header__nav li a:active {
    border-bottom-color: white;
    color: lightblue;
  }
}

.main {
  position: relative;
  min-height: 100vh;
}
.main a {
  font-weight: 700;
}

.section {
  position: relative;
  min-height: calc(100vh - var(--header-height));
  padding: 30px;
  overflow: hidden;
}
.section--is-home {
  color: #005293;
  background-color: #fecb00;
}
.section--is-home .preamble {
  font-size: 1.41rem;
  letter-spacing: -0.5px;
}
@media (max-width: 360px) {
  .section--is-home {
    padding: 0;
  }
  .section--is-home h1 {
    font-size: 20vw;
  }
  .section--is-home .preamble {
    font-size: 6.2vw;
  }
}
.section--is-meet {
  background: linear-gradient(to top left, #fecb00, white);
}
.section--is-venue {
  min-height: 0;
  background-image: url("../img/clouds_c.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}
.section--is-agenda {
  color: #fecb00;
  background-color: #005293;
}
.section--is-register {
  min-height: 0;
}
.section--is-livestream {
  min-height: 0;
  color: white;
  background-color: #222;
}
.section--is-livestream a {
  color: white;
}

.content--is-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
}

.footer {
  position: relative;
  padding: 60px 30px 120px;
  font-family: "inconsolata", monospace;
  font-size: 0.875rem;
  color: white;
  background-color: #005293;
}
.footer a {
  color: white;
}
.footer__inner {
  position: relative;
}
.footer__logo {
  margin-bottom: 30px;
  line-height: 0;
}
.footer__logo__link {
  display: inline-block;
}
.footer__logo__img {
  width: 98px;
}
.footer__row {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 30px;
}
.footer__col {
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 25%;
}
@media (max-width: 1024px) {
  .footer__col {
    margin-bottom: 15px;
    flex: 0 0 33.333%;
  }
}
@media (max-width: 768px) {
  .footer__col {
    flex: 0 0 50%;
  }
}
@media (max-width: 480px) {
  .footer__col {
    flex: 0 0 100%;
  }
}
.footer__nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.footer__nav a {
  color: white;
  text-decoration: none;
}
.footer__social-media {
  margin-bottom: 15px;
}
.footer__social-media .footer__nav ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.footer__social-media .footer__nav ul li {
  margin: 0 15px 5px;
}
.footer__copyright {
  font-size: 0.75rem;
  text-align: center;
}