.inner {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;

  &--is-xxl {
    max-width: $inner-xxl;
  }

  &--is-xl {
    max-width: $inner-xl;
  }

  &--is-l {
    max-width: $inner-l;
  }

  &--is-m {
    max-width: $inner-m;
  }

  &--is-s {
    max-width: $inner-s;
  }

  &--is-xs {
    max-width: $inner-xs;
  }

  &--is-xxs {
    max-width: $inner-xxs;
  }
}
