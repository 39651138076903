body {
  font-family: "opensans", helvetica, arial, sans-serif;
  font-size: 100%;
  line-height: 1.4;
  color: $color-black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 calc($gutter / 2);
  font-family: "opensans", helvetica, arial, sans-serif;
  font-weight: 400;
  letter-spacing: -1px;
}

h1 {
  margin: 0 0 4px;
  font-size: rem-calc(72px);
  line-height: 0.85;
  font-weight: 700;
  letter-spacing: -4px;
}

h2 {
  font-size: rem-calc(46px);
}

h3 {
  font-size: rem-calc(32px);
}

h4 {
  font-size: rem-calc(32px);
}

h5 {
  font-size: rem-calc(28px);
}

h6 {
  font-size: rem-calc(24px);
}

p {
  margin: 0 0 calc($gutter / 2);

  &:empty {
    display: none;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

a {
  color: $color-blue;
}

small,
figcaption {
  font-family: "inconsolata", monospace;
  font-size: 0.9rem;
  letter-spacing: -0.5px;
}

.title {
  line-height: 1;
  font-weight: 700;
}

.pretitle {
  display: inline-block;
  margin: 0 0 8px;
  border-radius: 2px;
  padding: 4px 6px 2px;
  font-family: "inconsolata", monospace;
  font-size: 1rem;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  color: $color-yellow;
  background-color: $color-blue;

  &--is-inverted {
    color: $color-blue;
    background-color: $color-yellow;
  }
}

.preamble {
  font-size: 1.25rem;
  letter-spacing: -0.5px;
}

.details {
  font-size: 1rem;
}

// trim text to a specific number of rows
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
