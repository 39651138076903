/**
 * Row
 */
.row {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-left: calc(0px - $gutter / 2);
  margin-right: calc(0px - $gutter / 2);
  margin-bottom: $gutter;

  &:last-child {
    margin-bottom: 0;
  }

  &--is-reversed {
    flex-direction: row-reverse;

    // mobile layout
    @media (max-width: $inner-s) {
      flex-direction: row;
    }
  }
}
