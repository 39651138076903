.attendee-list {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    span:first-child {
      font-weight: 700;
    }

    span:last-child {
      font-style: italic;
    }
  }
}
