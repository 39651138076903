@font-face {
  font-family: "inconsolata";
  src: url("../fonts/inconsolata-regular.woff2") format("woff2"),
    url("../fonts/inconsolata-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "inconsolata";
  src: url("../fonts/inconsolata-bold.woff2") format("woff2"),
    url("../fonts/inconsolata-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "opensans";
  src: url("../fonts/opensans-regular.woff2") format("woff2"),
    url("../fonts/opensans-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "opensans";
  src: url("../fonts/opensans-italic.woff2") format("woff2"),
    url("../fonts/opensans-italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "opensans";
  src: url("../fonts/opensans-bold.woff2") format("woff2"),
    url("../fonts/opensans-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
