.symbol {
  max-width: none;

  path {
    fill: rgba(0, 82, 147, 0.5);
  }

  &--is-domino {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: 100vh;
    transform: rotate(15deg) translate(-50%, -50%);

    path {
      fill: rgba(0, 82, 147, 0.25);
    }
  }

  &--is-grade-up {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: auto;
    transform: rotate(-45deg);
  }

  &--is-epsilon {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 140px;
    height: auto;
    transform: rotate(40deg);
  }
}
