.section {
  position: relative;
  min-height: calc(100vh - var(--header-height));
  padding: $gap;
  overflow: hidden;

  // home section
  &--is-home {
    color: $color-blue;
    background-color: $color-yellow;

    .preamble {
      font-size: 1.41rem;
      letter-spacing: -0.5px;
    }

    @media (max-width: 360px) {
      padding: 0;

      h1 {
        font-size: 20vw;
      }

      .preamble {
        font-size: 6.2vw;
      }
    }
  }

  // meet section
  &--is-meet {
    //min-height: 0;
    background: linear-gradient(to top left, $color-yellow, white);
  }

  &--is-venue {
    min-height: 0;
    background-image: url("../img/clouds_c.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
  }

  &--is-agenda {
    color: $color-yellow;
    background-color: $color-blue;
  }

  &--is-register {
    min-height: 0;
  }

  &--is-livestream {
    min-height: 0;
    color: white;
    background-color: $color-black-matte;

    a {
      color: white;
    }
  }
}
