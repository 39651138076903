* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

html {
  scroll-padding-top: var(--header-height);
  background-color: $color-blue;
}

body {
  padding-top: var(--header-height);
  background-color: $color-white;
}

body,
.wrapper {
  overflow-x: hidden;
}

figure {
  position: relative;
  margin: 0;
}

img {
  border: 0;
  max-width: 100%;
  height: auto;
}

iframe,
video {
  width: 100%;
  height: auto;
}

label {
  display: block;
  font-family: "inconsolata", monospace;
  font-size: 1rem;
  line-height: 1;
}

input[type="text"],
input[type="email"] {
  border: 1px solid $color-blue;
  width: 100%;
  padding: 10px;
  font-family: "inconsolata", monospace;
  font-size: 1.2rem;
  line-height: 1;
}
