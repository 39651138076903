/**
 * Column
 */
.col {
  padding-left: calc($gutter / 2);
  padding-right: calc($gutter / 2);
  flex: 0 0 50%;

  &__inner {
    padding: $gutter;
  }

  &--is-frosted {
    .col__inner {
      border-radius: 2px;
      background-color: rgba(255, 255, 255, 0.15);
      backdrop-filter: blur(10px);
    }
  }

  &--has-symbols {
    height: 60vh;

    figure {
      height: 100%;
    }
  }

  // go to one column
  @media (max-width: $inner-s) {
    margin-bottom: $gutter;
    flex: 0 0 100%;

    &--has-symbols {
      height: 100px;
    }
  }
}
