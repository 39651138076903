.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  height: var(--header-height);
  padding-left: $gutter;
  padding-right: $gutter;
  color: $color-black-matte;
  background-color: rgba(248, 248, 255, 0.95);

  &__inner {
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    position: relative;
    height: 18px;
    line-height: 0;

    &__link {
      position: relative;
      display: block;
      height: 100%;
    }

    &__img {
      max-width: none;
      width: auto;
      height: 100%;

      path {
        fill: $color-black;
      }
    }
  }

  // mobile nav btn
  &__btn {
    display: none;
    border-radius: 0;
    border-color: transparent;
    width: var(--header-height);
    height: 100%;
    padding: 0;
    background-color: transparent;

    svg {
      path {
        fill: $color-blue;
      }
    }

    @media (hover: hover) {
      &:hover {
        transform: none;
        transition: none;
      }
    }

    @media (max-width: $inner-s) {
      display: inline-flex;

      // mobile nav open
      &[aria-expanded="true"] {
        & + .header__nav {
          display: block;
          background-color: $color-blue;
        }
      }
    }
  }

  &__nav {
    position: relative;
    height: 100%;

    ul {
      display: flex;
      margin: 0;
      height: 100%;
      padding: 0;
      //column-gap: $gutter;
      align-items: center;
      list-style: none;
    }

    li {
      display: flex;
      height: 100%;

      // current page
      &.current-menu-item,
      &.current_page_item {
        border-bottom-color: $color-blue;

        a {
          border-bottom-color: $color-blue;
          color: $color-blue;
        }
      }

      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        border-bottom: 2px solid transparent;
        padding: 0 15px;
        font-family: "inconsolata", monospace;
        font-size: rem-calc(14px);
        font-weight: 700;
        color: $color-black-matte;
        text-transform: uppercase;

        &:focus {
          border-bottom-color: $color-blue;
          color: $color-blue;
        }

        @media (hover: hover) {
          &:hover {
            border-bottom-color: $color-blue;
            color: $color-blue;
          }
        }

        &:active {
          border-bottom-color: $color-green;
          color: $color-blue;
        }
      }
    }

    // mobile nav layout
    @media (max-width: $inner-s) {
      display: none;
      position: fixed;
      top: var(--header-height);
      height: calc(100vh - var(--header-height));
      left: 0;
      right: 0;
      z-index: 999;
      overflow-y: scroll;

      ul {
        display: block;
        height: auto;
      }

      li {
        display: block;
        height: auto;

        // current page
        &.current-menu-item,
        &.current_page_item {
          border-bottom-color: $color-black;

          a {
            border-bottom-color: $color-yellow;
            color: $color-yellow;
          }
        }

        a {
          border-bottom: 1px dashed $color-yellow;
          padding: $gutter;
          font-size: rem-calc(18px);
          color: $color-yellow;
          font-weight: 400;
          text-transform: none;

          &:focus {
            border-bottom-color: $color-yellow;
            color: white;
          }

          @media (hover: hover) {
            &:hover {
              border-bottom-color: white;
              color: $color-yellow;
            }
          }

          &:active {
            border-bottom-color: white;
            color: $color-blue-pale;
          }
        }
      }
    }
  }
}
