$color-black: #000;
$color-black-matte: #222;
$color-black-pale: #222;

$color-gray-soft: #f5f5f7;

$color-white: #fff;
$color-white-pale: ghostwhite;

$color-blue: #005293;
$color-blue-pale: lightblue;

$color-yellow: #fecb00;

$color-green: green;
$color-green-pale: lightgreen;
