*:focus {
  outline: 2px dotted $color-black;
}

.a11y {
  &-sr-only {
    position: absolute;
    border: 0;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    clip: rect(0, 0, 0, 0);
  }

  &-skip-to {
    position: absolute;
    display: inline-block;
    left: -100%;
    z-index: -999;
    width: 1px;
    height: 1px;
    overflow: hidden;

    &:focus,
    &:active {
      left: 50%;
      z-index: 9999;
      margin: 0 auto;
      border: 2px solid $color-black;
      width: auto;
      height: auto;
      padding: 15px;
      font-size: 1rem;
      line-height: 1.25;
      color: $color-black;
      text-align: center;
      overflow: auto;
      background-color: $color-white;
      transform: translateX(-50%);
    }
  }
}
