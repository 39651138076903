/**
 * Css variables
 */
:root {
  --header-height: 80px;
}

/**
 * Layout 
 * Inner width sizes used in components and media queries. 
 */
$inner-xxl: 1680px;
$inner-xl: 1440px;
$inner-l: 1280px;
$inner-m: 1024px;
$inner-s: 768px;
$inner-xs: 480px;
$inner-xxs: 320px;

$gutter: 30px;
$gap: 30px;
