.agenda-list {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin-bottom: calc($gutter / 2);
  }

  &__time {
    display: block;
    margin-right: 15px;
    font-family: "inconsolata", monospace;
  }

  &__title {
    margin-bottom: 4px;
    font-size: 1.1rem;
    font-weight: 700;
  }

  &__desc {
    font-family: "inconsolata", monospace;
    font-size: 0.9rem;
  }
}
