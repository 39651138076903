.footer {
  position: relative;
  padding: 60px $gutter 120px;
  font-family: "inconsolata", monospace;
  font-size: rem-calc(14px);
  color: white;
  background-color: $color-blue;

  a {
    color: white;
  }

  &__inner {
    position: relative;
  }

  &__logo {
    margin-bottom: $gutter;
    line-height: 0;

    &__link {
      display: inline-block;
    }

    &__img {
      width: 98px;
    }
  }

  &__row {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: calc(0px - $gutter / 2);
    margin-right: calc(0px - $gutter / 2);
    margin-bottom: $gutter;
  }

  &__col {
    padding-left: calc($gutter / 2);
    padding-right: calc($gutter / 2);
    flex: 0 0 25%;

    @media (max-width: $inner-m) {
      margin-bottom: calc($gutter / 2);
      flex: 0 0 33.333%;
    }

    @media (max-width: $inner-s) {
      flex: 0 0 50%;
    }

    @media (max-width: $inner-xs) {
      flex: 0 0 100%;
    }
  }

  &__nav {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    a {
      color: white;
      text-decoration: none;
    }
  }

  &__social-media {
    margin-bottom: 15px;

    .footer__nav {
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        li {
          margin: 0 calc($gutter / 2) 5px;
        }
      }
    }
  }

  &__copyright {
    font-size: rem-calc(12px);
    text-align: center;
  }
}
