.button,
button,
button[type="submit"],
input[type="submit"],
.wp-block-button__link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin: 0;
  border: 1px solid $color-blue;
  border-radius: 100px;
  padding: 15px 30px;
  font-family: "inconsolata", monospace;
  font-size: 1rem;
  line-height: inherit;
  color: white;
  background-color: $color-blue;
  text-decoration: none;
  appearance: none;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.3s ease-out;

  svg {
    max-width: none;
    width: auto;
    height: 16px;
  }

  &:focus {
    transform: scale(1.05);
  }

  @media (hover: hover) {
    &:hover {
      transform: scale(1.05);
    }
  }

  &:active {
    transform: scale(1);
  }

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
}
